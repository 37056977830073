@import '../../../styles/variables';

.wrapper {
  max-width: 51.875rem;
  margin: 0 auto;
  padding: 7.5rem 2rem 7.5rem;

  @media screen and (max-width: $tablet-large-screen) {
    padding: 3.75rem 2rem 3.75rem;
  }

  .content {
    display: flex;
    gap: 1.5rem;

    @media screen and (max-width: $mobile-medium-screen) {
      gap: 0.5rem;
    }
  }

  .quotes {
    width: 100%;
  }

  .test {
    width: 100%;
    height: auto;
  }

  .content-black {
    padding: 0 0 2.5rem 0;
    color: $white;
    @include quote-m;

    @media screen and (max-width: $mobile-medium-screen) {
      @include quote-s;
    }
  }

  .content-white {
    padding: 0 0 2.5rem 0;
    color: $gray-2;
    @include quote-m;

    @media screen and (max-width: $mobile-medium-screen) {
      @include quote-s;
    }
  }

  .testimonial-wrapper {
    display: flex;
    flex-direction: column;
  }

  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    .img-wrapper {
      max-width: 77px;
      width: 100%;

      @media screen and (max-width: $mobile-medium-screen) {
        max-width: 50px;
      }

      .img {
        width: 100%;
        height: auto;
      }
    }

    .avatar {
      display: flex;
      flex-direction: column;
    }

    .title-black {
      color: $white;
      @include subheader-3;
    }

    .subtitle-black {
      color: $white;
      @include subheader-4;

      a {
        padding: 0;
      }
    }

    .title-white {
      color: $gray-2;
      @include subheader-3;
    }

    .subtitle-white {
      color: $gray-2;
      @include subheader-4;

      a {
        color: $gray-2;
        padding: 0;
      }
    }
  }
}
