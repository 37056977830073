@import '../../../styles/variables';

.faq {
  padding: 5rem 0 5rem;
  background-color: $white;

  @media screen and (max-width: $tablet-large-screen) {
    padding: 5rem 0 5rem;
  }

  @media screen and (max-width: $tablet-medium-screen) {
    padding: 5rem 0 5rem;
  }

  @media screen and (max-width: $mobile-medium-screen) {
    padding: 5rem 0 5rem;
  }

  .wrapper {
    max-width: $max-width;
    margin: 0 auto;
    padding: 5rem 1rem;
    display: flex;
    justify-content: center;
    gap: 4rem;

    @media screen and (max-width: $tablet-large-screen) {
      gap: 2rem;
    }

    @media screen and (max-width: $tablet-medium-screen) {
      flex-direction: column;
      padding: 3rem 1rem;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .sticky {
    position: sticky;
    top: 90px;

    @media screen and (max-width: tablet-medium-screen) {
      position: relative;
    }
  }

  .subheader {
    color: $gray-3;
    @include subheader-3;
  }

  .title {
    @include header-3;
  }

  .subtitle {
    max-width: 300px;
    color: $gray-3;
    @include subheader-4;
  }

  .content {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    width: 100%;
    gap: 1rem;

    @media screen and (max-width: $mobile-medium-screen) {
      gap: 0.5rem;
    }
  }
}
