@import '../../../styles/variables';

.portfolio {
  @include diagonal-contaier($light-3, 0rem);
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  max-width: $max-width;
  gap: 2rem;
  margin: 0 auto;
  padding: 15rem 1rem 20rem;

  @media screen and (max-width: $tablet-large-screen) {
    display: flex;
    flex-direction: column;
    padding: 2.5rem 1rem;
  }

  .container {
    grid-column: 1 / 2;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media screen and (max-width: $tablet-large-screen) {
      margin: 0 auto;
      margin-top: 5rem;
    }

    @media screen and (max-width: $tablet-medium-screen) {
      margin-top: 2rem;
    }

    .title {
      max-width: 425px;
      color: $gray-1;
      @include header-3;

      @media screen and (max-width: $mobile-extra-small-screen) {
        @include header-3;
      }
    }

    .subtitle {
      max-width: 460px;

      color: $gray-6;
      @include subheader-2;
    }

    .color {
      color: $orange-2;
      @include subheader-2;
    }
  }

  .technology-container {
    max-width: 700px;
    width: 100%;
    position: absolute;
    top: 6rem;
    right: 2rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;

    @media screen and (max-width: $dekstop-screen-xl) {
      max-width: 650px;
      width: 100%;
    }

    @media screen and (max-width: $desktop-screen) {
      max-width: 550px;
      width: 100%;
      right: 0.8rem;
    }

    @media screen and (max-width: $tablet-large-screen) {
      display: none;
    }

    .left-container {
      max-width: 341px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      transform: translateY(-5rem);

      img {
        width: 100%;
        height: auto;
      }
    }

    .right-container {
      max-width: 341px;
      width: 100%;
      transform: translateY(-9.5rem);
      display: flex;
      flex-direction: column;
      gap: 1rem;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .mobile-container {
    display: none;

    @media screen and (max-width: $tablet-large-screen) {
      display: flex;
      flex-direction: row;
      padding-bottom: 10rem;
      overflow-x: scroll;
      width: 100%;
      gap: 1rem;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
