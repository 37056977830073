@import '../../../styles/variables';

.heroHeader {
  color: #E7E5E4;
  font-size: 50px;
  font-family: Sora, sans-serif;
  font-weight: 600;
  line-height: 110%;
  letter-spacing: -1px;

  @media screen and (max-width: $tablet-medium-screen) {
    font-size: 42px;
    margin-bottom: 32px;
  }

  @media screen and (max-width: $mobile-small-screen) {
    font-size: 42px;
  }
}

.headerLayout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;

  p {
    color: #E4E4E7;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: -0.18px;
    max-width: 453px;
    font-weight: 400;

    @media screen and (max-width: $tablet-medium-screen) {
      font-size: 16px;
    }

    @media screen and (max-width: $mobile-small-screen) {
      font-size: 16px;
    }

  }

  @media screen and (max-width: $tablet-medium-screen) {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: $mobile-small-screen) {
    grid-template-columns: 1fr;
  }
}

.heroHeaderWrapper {
  min-width: 720px;

  @media screen and (max-width: $tablet-medium-screen) {
    min-width: 100%;
  }
}

.highlight {
  display: inline;
  color: rgba(234, 88, 12, 1)
}

.flex {
  display: flex;
  flex-wrap: wrap;
  align-content: center;

  >* {
    margin-right: 8px;

    @media screen and (max-width: $mobile-medium-screen) {
      margin-bottom: 8px;
    }

    @media screen and (max-width: $mobile-extra-small-screen) {
      margin-bottom: 8px;
    }
  }


}

.hero {
  width: 100%;
  overflow: hidden;
  padding: 80px 0;
  background-color: $gray-7;

  @media screen and (max-width: $tablet-medium-screen) {
    padding: 40px 0;
  }

  @media screen and (max-width: $mobile-small-screen) {
    padding: 40px 0;
  }

  .wrapper {
    max-width: $max-width;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 0 auto;
    padding: 0 1rem;
    position: relative;

    @media screen and (max-width: $desktop-screen) {
      padding-bottom: 1rem;
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      max-width: 48.125rem;
      margin: 0 7.5rem 7.5rem;

      @media screen and (max-width: $desktop-screen) {
        margin: 0 auto;
      }

      .title {
        color: $light-6;
        @include header-1;

        @media screen and (max-width: $mobile-medium-screen) {
          @include header-2;
        }

        @media screen and (max-width: $mobile-extra-small-screen) {
          @include header-extra-small-mobile;
        }

        .color {
          @include header-1;
          color: $orange-2;

          @media screen and (max-width: $mobile-medium-screen) {
            @include header-2;
          }

          @media screen and (max-width: $mobile-extra-small-screen) {
            @include header-extra-small-mobile;
          }
        }
      }

      .subtitle {
        color: $light-5;
        @include subheader-2;
      }

      .link {
        margin-top: 0.5rem;
        padding-left: 0.2rem;
        color: $light-5;
        @include body-3;

        a {
          color: $light-5;
          @include body-3;
          padding: 0;
          text-decoration: underline;
        }
      }
    }

    .img {
      width: 100%;
      height: auto;
    }
  }
}