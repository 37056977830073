@import '../../styles/variables';

.button-primary {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border: none;
  color: $white;
  height: 2.5rem;
  padding: 0.5rem;
  cursor: pointer;
  background-color: $orange-2;
  border-radius: $border-radius;
  white-space: nowrap;
  @include button-s;

  &:hover:enabled {
    background-color: $orange-7;
  }

  // @media screen and (max-width: $mobile-small-screen) {
  //   height: 1.8rem;
  //   padding: 0.5rem;
  //   font-size: 10px;
  // }
}

.disabled {
  &:hover {
    cursor: not-allowed;
  }
}

.button-secondary {
  color: $white;
  background-color: $gray-8;
  border: 1px solid $gray-4;
  max-width: 18rem;
  padding: 1rem 1.5rem;
  border-radius: $border-radius;
  @include button-m;

  &:hover:enabled {
    background-color: $gray-7;
    border: 1px solid $gray-9;
  }

  // @media screen and (max-width: 23.5em) {
  //   max-width: 12rem;
  //   padding: 0.5rem 0.2rem;
  //   @include caption-s;
  // }
}

.button-primary-round {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border: none;
  color: $white;
  min-width: 250px;
  height: 50px;

  background-color: $orange-2;
  border-radius: 50px;
  white-space: nowrap;
  @include button-m;

  &:hover:enabled {
    background-color: $orange-7;
  }

  // @media screen and (max-width: $mobile-small-screen) {
  //   height: 1.8rem;
  //   padding: 0.5rem;
  //   font-size: 10px;
  // }
}

.button-secondary-round {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border: none;
  color: $white;
  min-width: 250px;
  height: 50px;

  background-color: $orange-2;
  border-radius: 50px;
  white-space: nowrap;
  @include button-s;

  &:hover:enabled {
    background-color: $orange-7;
  }

  // @media screen and (max-width: $mobile-small-screen) {
  //   height: 1.8rem;
  //   padding: 0.5rem;
  //   font-size: 10px;
  // }
}

.button-secondary-round {
  color: $white;
  background-color: $gray-8;
  border: 1px solid $gray-4;
  min-width: 250px;
  height: 50px;
  border-radius: 50px;

  padding: 0 2rem;

  @include button-m;

  &:hover:enabled {
    background-color: $gray-7;
    border: 1px solid $gray-9;
  }

  // @media screen and (max-width: 23.5em) {
  //   max-width: 12rem;
  //   padding: 0.5rem 0.2rem;
  //   @include caption-s;
  // }
}
