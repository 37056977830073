@import '../../../styles/variables';

.intouch {
  position: relative;
  isolation: isolate;
  width: 100%;
  display: flex;
  flex-direction: column;

  &::before,
  &::after {
    content: '';
    background-color: $gray-7;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
  }

  &::after {
    transform: skewY(-5deg);
    transform-origin: top left;
  }

  .wrapper {
    max-width: $max-width;
    margin: 0 auto;
    padding: 0 0.5rem 2rem;
    display: flex;
    align-items: center;
    gap: 3rem;

    @media screen and (max-width: $tablet-large-screen) {
      gap: 1rem;
    }

    @media screen and (max-width: $tablet-medium-screen) {
      flex-direction: column;
      align-items: flex-start;
      padding: 4rem 0 4.6rem;
    }

    @media screen and (max-width: $mobile-extra-small-screen) {
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 317px;
    color: $light-2;

    @media screen and (max-width: $tablet-large-screen) {
      padding: 0 1.875rem 1rem;
    }
  }

  .subheader {
    @include caption-m;
  }

  .title {
    @include header-3;
  }

  .subtitle {
    @include subheader-2;
  }

  .discord-wrapper {
    max-width: 600px;
    width: 100%;
    position: relative;
    gap: 1rem;
    background-color: $white;
    padding: 5rem 6.5rem;
    border-radius: $border-radius;
    transform: translateY(-20%);

    &::before,
    &::after {
      content: '';
      background-color: $white;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: calc(100% + 30%);
    }

    &::after {
      transform: skewY(-5deg);

      transform-origin: top left;
    }

    @media screen and (max-width: $tablet-medium-screen) {
      &::before,
      &::after {
        display: none;
      }

      &::after {
        display: none;
      }

      transform: translateY(0rem);
      padding: 4.375rem 1.875rem;
    }
  }

  .discord-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    transform: translateY(40%);

    @media screen and (max-width: $tablet-medium-screen) {
      transform: translateY(0rem);
    }
  }

  .discord-header {
    @include special-header-2;

    @media screen and (max-width: $mobile-extra-small-screen) {
      @include header-4;
    }
  }

  .discord-subheader {
    color: $gray-3;
    @include subheader-4;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
}
