@import '../../../styles/variables';

.box {
  background-color: rgba(24, 24, 27, 1);
  padding: 1.875rem;
  border-radius: $border-radius;

  @media screen and (max-width: $mobile-medium-screen) {
    padding: 1rem;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.375rem;

    @media screen and (max-width: $mobile-medium-screen) {
      gap: 1rem;
    }

    .emoji {
      @include emoji-2;
    }

    .title {
      color: $light-2;
      @include header-5;

      @media screen and (max-width: $mobile-extra-small-screen) {
        @include header-6;
      }
    }

    .content {
      color: $light-6;
      @include body-2;

      @media screen and (max-width: $mobile-extra-small-screen) {
        @include body-3;
      }
    }
  }
}
