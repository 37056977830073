@import '../../../styles/variables';

.fw-500 {
  font-weight: 500 !important;
}

.reinvented {
  @include diagonal-contaier($gray-5, 1rem);
  background-color: $gray-7;

  @media screen and (max-width: $tablet-large-screen) {
    padding-top: 7rem;
  }

  @media screen and (max-width: $mobile-medium-screen) {
    padding-top: 5rem;
  }

  .wrapper {
    max-width: $max-width;
    margin: 0 auto;
    padding: 4rem 1rem 3rem;

    @media screen and (max-width: $tablet-small-screen) {
      padding: 0 1.25rem 0;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1rem;
    padding-bottom: 2rem;
  }

  .subtitle {
    color: $gray-3;
    @include caption-m;
  }

  .title {
    color: $orange-1;
    @include special-header-1;

    @media screen and (max-width: $mobile-large-screen) {
      @include special-header-3;
    }
  }

  .subheader {
    max-width: 47.5rem;
    text-align: center;
    color: $gray-10;
    @include subheader-2;

    @media screen and (max-width: $mobile-extra-small-screen) {
      @include subheader-4;
    }
  }
}