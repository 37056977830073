@import '../../../styles/variables';

.appreciated {
  position: relative;
  isolation: isolate;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: $tablet-large-screen) {
    margin-top: -9rem;
  }

  @media screen and (max-width: $tablet-medium-screen) {
    margin-top: -11rem;
  }

  @media screen and (max-width: $mobile-small-screen) {
    margin-top: -12rem;
  }

  &::before,
  &::after {
    content: '';
    background-color: $gray-7;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
  }

  &::after {
    transform: skewY(-5deg);
    transform-origin: top left;
  }

  .wrapper {
    max-width: $max-width;
    margin: 0 auto;
    padding: 5rem 1rem;
    display: flex;
    gap: 1.5rem;

    @media screen and (max-width: $tablet-large-screen) {
      padding: 4rem 1rem 2rem;
    }

    @media screen and (max-width: $tablet-medium-screen) {
      flex-direction: column;
    }

    @media screen and (max-width: $mobile-extra-small-screen) {
      padding: 4rem 0.5rem 2rem;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 310px;
    color: $light-2;

    @media screen and (max-width: $tablet-medium-screen) {
      flex-direction: column;
      max-width: 100%;
      align-items: center;
      text-align: center;
    }
  }

  .subheader {
    @include caption-m;
  }

  .title {
    @include header-3;
  }

  .content {
    @include subheader-2;
  }

  .icons-container {
    display: flex;
    gap: 1rem;
    margin: 0 auto;

    @media screen and (max-width: $tablet-large-screen) {
      flex-direction: column;
      gap: 1.5rem;
    }
  }

  .img {
    width: 310px;
    height: auto;

    @media screen and (max-width: $mobile-extra-small-screen) {
      width: 240px;
    }
  }
}
