@import '../../../styles/variables';

.join {
  padding: 10rem 0 6.25rem;
  width: 100%;
  background-color: $light-1;

  @media screen and (max-width: $mobile-large-screen) {
    padding: 3.75rem 0 6.25rem;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: $max-width;
    margin: 0 auto;
    padding: 0 1rem;
    gap: 1rem;

    .subtitle {
      color: $gray-2;
      @include caption-m;
    }

    .title {
      color: $gray-2;
      @include header-1;

      @media screen and (max-width: $mobile-large-screen) {
        @include header-3;
      }

      @media screen and (max-width: $mobile-extra-small-screen) {
        @include header-extra-small-mobile;
      }
    }

    .subheader {
      max-width: 812px;
      color: $gray-3;
      @include subheader-2;

      @media screen and (max-width: $mobile-large-screen) {
        @include subheader-4;
      }
    }

    button {
      margin-top: 1rem;
      padding: 1.5rem 2.5rem;
      @include button-l;

      @media screen and (max-width: $mobile-large-screen) {
        padding: 1.5rem 1.5rem;
      }
    }
  }
}
