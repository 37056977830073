@import '../../styles/variables';

.singleProgram {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 4px;
  border: 1px solid var(--gray-8, #222225);
  padding: 50px 40px;
  row-gap: 24px;
  column-gap: 52px;
  cursor: pointer;

  @media screen and (max-width: $tablet-medium-screen) {
    grid-template-columns: 1fr;
    padding: 16px;
  }

  @media screen and (max-width: $mobile-small-screen) {
    grid-template-columns: 1fr;
    padding: 16px;
  }

}

.nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: center;
  gap: 8px;

  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }

  @media screen and (max-width: $tablet-large-screen) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
}

.chip {
  border-radius: 20px;
  background: var(--gray-800, #27272A);
  display: inline-flex;
  justify-content: center;
  padding: 6px 12px;
  align-items: center;
  gap: 4px;
  color: var(--gray-00-white, #FFF);
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.highlightedPill {
  @extend .chip;
  background: #FFEDD5;
  color: #F97316;
  border: 1px solid transparent;
}
.discount {
  font-size: 12px;
  text-decoration: line-through;
}

.title {
  color: var(--gray-11, #D6D3D1);
  font-size: 34px;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: -1.36px;
}

.shortDescription {
  color: var(--gray-11, #D6D3D1);
  /* Header 6 */
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.14px;
  margin: 24px 0;
}

.imageWrapper {
  border-radius: 4px;
  position: relative;
  max-height: 249px;
}

.programData {
  display: flex;
  flex-direction: column;
  justify-content: center;
}