@import '../../../styles/variables';

.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 4rem;
  margin-top: 8.5rem;

  @media screen and (max-width: 48em) {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    margin: 4rem 0;
  }

  .content-left,
  .content-right {
    grid-row: 1 / 2;
    width: 100%;
  }

  .content-left {
    grid-column: 1 / 2;
    width: 100%;
  }

  .content-right {
    grid-column: 2 / 3;
    width: 100%;
  }

  .title {
    display: flex;
    padding: 1rem 0;
    color: $orange-1;
    margin-bottom: 1rem;
    align-items: center;
    @include header-4;

    @media screen and (max-width: $mobile-small-screen) {
      @include header-5;
      padding: 0.5rem 0;
    }
  }

  .subtitle {
    color: $white;
    @include subheader-2;

    @media screen and ($tablet-medium-screen) {
      max-width: 34rem;
    }

    @media screen and (max-width: $mobile-large-screen) {
      @include subheader-4;
    }
  }

  .wrapper-left {
    grid-column: 2 / 3;
    width: 100%;
  }

  .wrapper-right {
    grid-column: 1 / 2;
    width: 100%;
  }

  .img {
    width: 100%;
    height: 100%;
  }
}
