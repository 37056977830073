@import '../../../styles/variables';

.industry {
  @include diagonal-contaier($gray-7, 0);
  background-color: $gray-7;

  .wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 1rem;
    max-width: $max-width;
    margin: 0 auto;
    padding: 0 1rem;

    .subtitle {
      color: $gray-3;
      @include caption-m;
    }

    .title {
      color: $light-3;
      @include header-3;

      @media screen and (max-width: $mobile-extra-small-screen) {
        @include header-extra-small-mobile;
      }

      .color {
        color: $orange-1;
        @include header-3;

        @media screen and (max-width: $mobile-extra-small-screen) {
          @include header-extra-small-mobile;
        }
      }
    }

    .subheader {
      max-width: 47.2rem;
      margin-left: auto;
      margin-right: 0;
      color: $light-3;
      @include subheader-2;

      @media screen and (max-width: $mobile-extra-small-screen) {
        @include subheader-4;
      }
    }
  }

  .grid {
    padding: 4rem 1rem 50px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem;
    max-width: 67rem;
    margin: 0 auto;

    @media screen and (max-width: $tablet-large-screen) {
      grid-template-columns: repeat(2, 1fr);
      gap: 2.5rem;
      padding-left: 2.25rem;
      padding-right: 2.25rem;
    }

    @media screen and (max-width: $tablet-medium-screen) {
      grid-template-columns: 1fr;
      gap: 2.5rem;
      padding-top: 2rem;
      padding: 4rem 1.25rem 0;
    }
  }
}