@import '../../../styles/variables';

.programs {
  width: 100%;
  overflow: hidden;
  padding: 0 0 80px 0;
  background-color: $gray-7;

  @media screen and (max-width: $tablet-medium-screen) {
    padding-top: 5rem;
  }

  @media screen and (max-width: $mobile-small-screen) {
    padding-top: 2rem;
  }
}

.programsGrid {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 24px;
}