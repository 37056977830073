@import '../../../styles/variables';

.testimonials-black {
  padding: 0;
  position: relative;
  isolation: isolate;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;

  &::before,
  &::after {
    content: '';
    background-color: $gray-7;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
  }

  &::after {
    top: 0;
    left: 0;
    transform: skewY(-5deg);
    transform-origin: bottom right;
  }
}

.testimonials-white {
  padding: 0 0 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: $light-3;
}
