@import '../../../styles/variables';

.wrapper {
  border-radius: $border-radius;
  overflow: hidden;
}

.toggle {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  background-color: $light-1;
  transition: 0.3s;
}

.question {
  padding: 2rem 3rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;

  @media screen and (max-width: $tablet-medium-screen) {
    padding: 1.875rem 2.5rem;
  }

  @media screen and (max-width: $mobile-medium-screen) {
    padding: 1.875rem 2rem;
  }

  @media screen and (max-width: $mobile-extra-small-screen) {
    padding: 1rem 1rem;
  }
}

.title {
  color: $gray-1;
  transition: 0.3s;
  @include header-5;

  @media screen and (max-width: $mobile-medium-screen) {
    @include subheader-3;
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  padding: 0 3rem 2rem;

  @media screen and (max-width: $tablet-large-screen) {
    padding: 0 2.5rem 1rem;
  }

  @media screen and (max-width: $mobile-medium-screen) {
    padding: 0 2rem 1rem;
  }

  @media screen and (max-width: $mobile-extra-small-screen) {
    padding: 0 1rem 1rem;
  }
}

.subtitle {
  @include body-1;

  @media screen and (max-width: $mobile-medium-screen) {
    @include body-2;
  }
}

.collapse {
  background-color: $light-1;

}
